<template>
    <div>
        <div class="vx-row mt-3">
            <header class="vx-col w-full sm:w-1/2 mb-10">
                <h4>{{ $t("apps.configurator.version-list") }}</h4>
                <p>{{ $t("apps.configurator.version-list-subtitle") }}</p>
            </header>
            <div v-if="userHasAdminAppsDrm && isAdminUser" class="vx-col w-full sm:w-1/2 mb-10 text-right space-x-4">
                <vs-button class="xs:w-full sm:w-auto rounded-lg" @click="createVersion">
                    {{ $t("apps.configurator.createVersion") }}
                </vs-button>
            </div>
        </div>
        <div class="bg-white p-8">
            <SKCollapse ref="collapse" :accordion=true>
                <SKCollapseItem v-for="major in versionMajors" :key="major">
                    <template v-slot:header>
                        <div class="flex items-center">
                            <div>
                                <span class="text-lg font-medium">Version #{{ major }}</span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:content>
                        <div class="negative-margin">
                            <Datatable
                                :config="datatableConfig"
                                :elements="versionsGroupByMajor[major]"
                                :stripe="true"
                                :customActions = "customActions"
                                :draggable="false"
                                :sst="true"
                                @action="action"
                            />
                        </div>
                    </template>
                </SKCollapseItem>
            </SKCollapse>
        </div>
        <ModalInfo
            :modalOpen=modalOpen
            :type=modalType
            :headingText=headingModalText
            :text=modalText
            @closedModal="modalOpen = false"
        />
    </div>
</template>

<script>
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import ConfiguratorVersionService from '@/modules/Apps/Services/Configurator/ConfiguratorVersionService'
import MenuButton from './components/datatable/MenuButton.vue';
import CheckButton from './components/datatable/CheckButton.vue';
import ReleaseButton from './components/datatable/ReleaseButton.vue';
import LayoutButton from './components/datatable/LayoutButton.vue';
import EditStyleButton from './components/datatable/EditStyleButton.vue';
import ResourceButton from './components/datatable/ResourceButton.vue';
import ModalInfo from '@/modules/Shared/Components/modal/ModalInfo.vue'
import SKCollapse from '@/modules/Shared/Components/collapse/SKCollapse.vue'
import SKCollapseItem from '@/modules/Shared/Components/collapse/SKCollapseItem.vue'
import { v4 as uuidv4 } from 'uuid';
import {userHasAuthorization} from "@/modules/Auth/Helpers/drmHelper";
import loader from "@/modules/Shared/Mixins/loader";
import moment from 'moment';

export default {
    name: 'versionList',
    mixins: [
        loader
    ],
    components: {
        SKCollapseItem,
        SKCollapse,
        Datatable,
        ModalInfo
    },
    data() {
        return {
            versionMajors: [],
            versionsGroupByMajor: [],
            versionList: [],
            modalOpen: false,
            modalType: "",
            headingModalText: "",
            modalText: "",
            versionsSearchable: null,
            statusSearchable: null,
            datatableConfig: [
                { 'type': 'html', 'field': 'status', head: this.$t("apps.configurator.status"), 'searchable': false, 'sorteable': true },
                { 'type': 'text', 'field': 'description', head: this.$t("apps.configurator.description"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'enable_menu_tab', head: this.$t("apps.configurator.enable-menu-tab"), 'searchable': false, 'sorteable': false, placeholder: this.$i18n.t('yes') },
                { 'type': 'actions', 'field': 'configuration', head: this.$t("apps.configurator.configuration"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'updated_at', head: this.$t("apps.configurator.updated_at"), 'searchable': false, 'sorteable': false },
                { 'type': 'actions', 'field': 'actions', head: this.$t("apps.configurator.actions"), 'searchable': false, 'sorteable': false }
            ],
            pagination: {},
            customActions: [
                {
                    actionName:'menu',
                    component: MenuButton,
                    props:{}
                },
                {
                    actionName:'check',
                    component: CheckButton,
                    props:{}
                },
                {
                    actionName:'release',
                    component: ReleaseButton,
                    props:{}
                },
                {
                    actionName:'resource',
                    component: ResourceButton,
                    props:{
                        isEditorUser: this.$store.state.auth.drm['admin'].length <= 0,
                    }
                },
                {
                    actionName:'layout',
                    component: LayoutButton,
                    props:{}
                },
                {
                    actionName:'editStyle',
                    component: EditStyleButton,
                    props:{}
                }
            ],
        }
    },
    computed: {
        userHasAdminAppsDrm() {
            return userHasAuthorization([{
                context: 'admin',
                drm: 'apps'
            }])
        },
        isAdminUser() {
            return this.$store.state.auth.drm['admin'].length > 0
        },
        configurationsEnabled() {
            if (!this.isAdminUser) {
                return ['menu', 'resource', ]
            }
            return ['menu', 'layout', 'resource', 'editStyle']
        },
    },
    async created() {
        let that = this
        this.loadAndNotifyError(async function () {
            await that.prepareDatatableConfig()
        })
    },
    methods: {
        sortVersions(versions) {
            const statusesOrder = ['publishing', 'published', 'cloning', 'draft', 'unpublished', 'obsolete']

            return versions.sort((a, b) => {
                return moment(b.updated_at).valueOf() - moment(a.updated_at).valueOf()
            }).sort((a, b) => {
                return statusesOrder.indexOf(a.status) - statusesOrder.indexOf(b.status)
            })
        },
        async prepareDatatableConfig()
        {
            const [publishedVersionsResponse, publishingVersionsResponse, lastVersionResponse] = await Promise.all([
                ConfiguratorVersionService.getVersionList({ q: `application_uuid:${this.$route.params.uuid},status:published` }),
                ConfiguratorVersionService.getVersionList({ q: `application_uuid:${this.$route.params.uuid},status:publishing` }),
                ConfiguratorVersionService.getVersionList({ q: `application_uuid:${this.$route.params.uuid}`, per_page: 1, sort_by: 'version_major', sort_type: 'desc' })
            ])

            this.versionMajors = publishedVersionsResponse.data.map((version) => version.version_major)
            this.versionMajors.push(...publishingVersionsResponse.data.map((version) => version.version_major))
            if (!this.versionMajors.includes(lastVersionResponse.data[0].version_major)) {
                this.versionMajors.push(lastVersionResponse.data[0].version_major)
            }
            this.versionMajors.sort().reverse()

            let newVersionsGroupByMajor = []

            const versionsGroupByMajor = await Promise.all(
                this.versionMajors.map(versionMajor => ConfiguratorVersionService.getVersionList({ q: `application_uuid:${this.$route.params.uuid},version_major:${versionMajor}` }))
            )

            versionsGroupByMajor.forEach(versionsInMajorGroup => {
                const versionsInMajor = versionsInMajorGroup.data

                newVersionsGroupByMajor[versionsInMajor[0].version_major] = this.sortVersions(versionsInMajor).map(version => ({
                    ...version,
                    'enable_menu_tab': version.enable_menu_tab ? this.$i18n.t('yes') : this.$i18n.t('no'),
                    'actions': this.actionsEnabled(version),
                    'configuration': this.configurationsEnabled,
                    'status': this.setStatus(version.status),
                    'updated_at': moment.unix(version.updated_at).format('DD/MM/YYYY HH:mm:ss')
                }))
            })

            this.versionsGroupByMajor = newVersionsGroupByMajor
            this.$refs.collapse.openItem(0);
        },
        actionsEnabled(version) {
            let allowedActions = this.userHasAdminAppsDrm
                ? ['check', 'edit', 'delete', 'clone','release']
                : ['check', 'clone', 'release'];

            if (version.status === 'draft') {
                allowedActions = ['check', 'edit', 'delete', 'release']
            }

            if (!this.userHasAdminAppsDrm && version.status === 'published') {
                allowedActions = allowedActions.filter(action => action !== 'release')
            }

            if (['publishing', 'cloning'].includes(version.status)) {
                allowedActions = []
            }

            return allowedActions
        },
        async createVersion() {
            this.$router.push({name: 'new-version'})
        },
        setStatus(status) {
            switch (status) {
                case 'draft': return `<div class="capitalize border border-warning text-warning py-2 px-4 rounded-full">${status}</div>`
                case 'published': return `<div class="capitalize border border-success text-success py-2 px-4 rounded-full">${status}</div>`
                case 'obsolete': return `<div class="capitalize border border-danger text-danger py-2 px-4 rounded-full">${status}</div>`
                case 'unpublished': return `<div class="capitalize border text-grey py-2 px-4 rounded-full">${status}</div>`
                case 'publishing': return `<div class="capitalize border border-info text-info py-2 px-4 rounded-full blink-me">${status}</div>`
                case 'cloning': return `<div class="capitalize border border-info text-info py-2 px-4 rounded-full blink-me">${status}</div>`
                default: return  `<div class="capitalize border border-info text-info py-2 px-4 rounded-full">${status}</div>`
            }
        },
        action(type, uuid) {
            switch (type) {
                case 'edit':
                    this.$router.push({name: 'edit-version', params: {"uuid": this.$route.params.uuid, "versionUuid": uuid}})
                    break
                case 'delete':
                    this.deleteVersion(uuid)
                    break
                case 'clone':
                    this.cloneVersion(uuid)
                    break
            }
        },
        async deleteVersion(uuid) {
            this.loadAndNotify(async () => {
                await ConfiguratorVersionService.deleteVersion(uuid)
                await this.prepareDatatableConfig()
            })
        },

        async cloneVersion(uuid) {
            this.$vs.dialog({
                type:'confirm',
                color:'primary',
                title: this.$t("apps.configurator.version.clone.title.confirmation"),
                text: this.$t("apps.configurator.version.clone.text.confirmation"),
                accept: () => this.cloneAction(uuid, this)
            })
        },

        async cloneAction(uuid) {
            this.loadAndNotify(async () => {
                await ConfiguratorVersionService.cloneVersion(uuid, uuidv4())
                try {
                    await this.prepareDatatableConfig()
                } catch (e) {
                    this.$router.go()
                }
            })
        }
    }
}
</script>
<style scoped>
    .negative-margin {
        margin: -2rem !important;
    }
</style>
<style>
    .blink-me {
        animation: blinker 0.8s ease-in-out infinite;
    }

    @keyframes blinker {
        50% {
            opacity: 0.4;
        }
    }
</style>
